.carousel-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
}

.promotional-carousel {
  width: 100%;
  height: auto;
  position: relative;
}

.carousel-image {
  width: 100%;
  height: 400px;
  object-fit: contain;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 24px;
  z-index: 1;
}

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}

.promotional-carousel .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 500px) {
  .promotional-carousel {
    height: auto;
  }
}
